import { render, staticRenderFns } from "./Profile.vue?vue&type=template&id=ced23842&"
import script from "./Profile.vue?vue&type=script&lang=js&"
export * from "./Profile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VInput } from 'vuetify/lib/components/VInput';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBtn,VInput,VSelect})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\vincenzo.nicolella\\Documents\\lavoro\\CodeCommit\\sorgenti\\geoviewer\\client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ced23842')) {
      api.createRecord('ced23842', component.options)
    } else {
      api.reload('ced23842', component.options)
    }
    module.hot.accept("./Profile.vue?vue&type=template&id=ced23842&", function () {
      api.rerender('ced23842', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Profile.vue"
export default component.exports