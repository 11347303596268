<template>
    <div class="container">
        <header class="jumbotron">
            <h3>
                Profilo:
                <strong>{{currentUser.fullName}}</strong>
            </h3>
        </header>
        <p>
            <v-input :messages="['']" prepend-icon="account_circle">{{currentUser.userName}}</v-input>
        </p>
        <p>
            <v-input :messages="[]" prepend-icon="email">{{currentUser.email}}</v-input>
        </p>
        <strong>Seleziona tra i seguenti file config (json) associati:</strong>
        <!-- {{configsUser}} -->
        <v-select class="mr-2"
                  :items="configsUser"
                  :item-text="configUserItemText"
                  :item-value="configUserItemValue"
                  v-model="configSelezionato"
                  outlined
                  menu-props="auto"
                  label="Seleziona"
                  return-object></v-select>


        <div v-if="configSelezionato !== null">
            <p>
                <strong>Dati del GeoViewer da avviare:</strong>
            </p>

            <v-input :messages="[configSelezionato.applicazione.versione]"
                     prepend-icon="apps">{{configSelezionato.applicazione.descrizione}}</v-input>

            <v-input :messages="[myMessageRuolo]"
                     prepend-icon="perm_identity">{{configSelezionato.ruolo.descrizione}}</v-input>

            <v-input :messages="[configSelezionato.fileConfig.descrizione]"
                     prepend-icon="description">{{configSelezionato.fileConfig.descrizione}}</v-input>

            <v-btn depressed
                   center
                   x-large
                   accent
                   color="primary"
                   @click="avviaGeoViewer">Avvia GeoViewerCore</v-btn>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Profile",
        data() {
            return {
                myMessageRuolo: "",
                descRuolo: "",
                myMessageConfig: "",
                descConfig: "",
                test:[{id:1},{id:2},{id:3},{id:4},{id:5},{id:6},{id:7},{id:8},{id:9},{id:0},{id:12},{id:13}],
                urlAppGeoViewer: `${process.env.VUE_APP_GEOVIEWER_URL}`,
                configSelezionato: null,
                configUtente: [
                    {
                        idOpzione: "config1.json",
                        testoDaMostrare: "Pippo",
                        urlApplicazione: ""
                    },
                    {
                        idOpzione: "config2.json",
                        testoDaMostrare: "Pluto",
                        urlApplicazione: ""
                    }
                ]
            };
        },
        methods: {
            // changeConfigSelezionato(e) {
            //   console.log("elemento selezionato::", e);
            //   this.descApplicazione = e.applicazione.descrizione;
            //   this.myMessageApp = "versione: " + e.applicazione.versione;
            //   this.descRuolo = "" + e.ruolo.descrizione;
            //   this.descConfig = "" + e.fileConfig.path;
            //   this.myMessageConfig = "" + e.fileConfig.descrizione;
            //   this.myVisible = true;
            //   //this.$router.push("/url-dove-deve-andare");
            // },
            avviaGeoViewer() {
                //alert(this.urlAppGeoViewer);

                console.log("/*--------LocalStorage::", localStorage);
                //debugger;

                var infoLogAttivita = {
                    "IP": "",
                    "config": this.configSelezionato.fileConfig.path,
                    "user": this.configSelezionato.utente.userName,
                    "userID": this.configSelezionato.utente.id,
                    "lnkApp": this.configSelezionato.applicazione.descrizione,
                    "applicazioneId": this.configSelezionato.applicazione.id , 
                    "configId": this.configSelezionato.fileConfig.id 
                };

                localStorage.setItem("infoLogAttivita", JSON.stringify(infoLogAttivita));
                //localStorage.infoLogAttivita = JSON.stringify(infoLogAttivita);
                console.log("avviaGeoViewer::",localStorage.infoLogAttivita);

                //debugger;

                //alert("Avvio GeoViewer");

                window.location =
                    this.urlAppGeoViewer +
                    `${this.configSelezionato.applicazione.path}?config=${this.configSelezionato.fileConfig.path}`;
            },
            configUserItemText(configUser) {
                return (
                    "" +
                    configUser.applicazione.descrizione +
                    " - " +
                    configUser.fileConfig.descrizione +
                    " (" +
                    configUser.fileConfig.path +
                    ")"
                );
            },
            configUserItemValue(configUser){
                // console.log("configUserItemValue::" + configUser.utenteFK+""+ configUser.ruoloFK+""+configUser.applicazioneFK+""+configUser.configFK);
                return (
                    configUser.utenteFK+""+ configUser.ruoloFK+""+configUser.applicazioneFK+""+configUser.configFK
                )
            
            }
        },
        computed: {
            setDescrizioneApplicazione() {
                return this.$store.state.auth.user.username;
            },
            currentUser() {
                return this.$store.state.auth.user;
            },
            configsUser() {
                return this.$store.getters["configuser/list"];
                //debugger;

                // var elencoConfig = this.$store.getters["configuser/list"];
                // console.log("this.$store.::", elencoConfig);
                // if (elencoConfig.length == 1) {
                //   this.avviaGeoViewer();
                //   return null;
                // } else return elencoConfig;
            }
        },
        async mounted() {
            if (!this.currentUser) {
                this.$router.push("/login");
            }

            await this.$store.dispatch(
                "configuser/loadListConfigsUser",
                this.currentUser
            );
            console.log("fuori");
            if (this.configsUser.length === 1) {
                console.log("/*--------LocalStorage::", localStorage);
                this.configSelezionato = this.configsUser[0];

                this.avviaGeoViewer();
            }
        }
    };
</script>