var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("header", { staticClass: "jumbotron" }, [
        _c("h3", [
          _vm._v(" Profilo: "),
          _c("strong", [_vm._v(_vm._s(_vm.currentUser.fullName))])
        ])
      ]),
      _c(
        "p",
        [
          _c(
            "v-input",
            { attrs: { messages: [""], "prepend-icon": "account_circle" } },
            [_vm._v(_vm._s(_vm.currentUser.userName))]
          )
        ],
        1
      ),
      _c(
        "p",
        [
          _c("v-input", { attrs: { messages: [], "prepend-icon": "email" } }, [
            _vm._v(_vm._s(_vm.currentUser.email))
          ])
        ],
        1
      ),
      _c("strong", [
        _vm._v("Seleziona tra i seguenti file config (json) associati:")
      ]),
      _c("v-select", {
        staticClass: "mr-2",
        attrs: {
          items: _vm.configsUser,
          "item-text": _vm.configUserItemText,
          "item-value": _vm.configUserItemValue,
          outlined: "",
          "menu-props": "auto",
          label: "Seleziona",
          "return-object": ""
        },
        model: {
          value: _vm.configSelezionato,
          callback: function($$v) {
            _vm.configSelezionato = $$v
          },
          expression: "configSelezionato"
        }
      }),
      _vm.configSelezionato !== null
        ? _c(
            "div",
            [
              _vm._m(0),
              _c(
                "v-input",
                {
                  attrs: {
                    messages: [_vm.configSelezionato.applicazione.versione],
                    "prepend-icon": "apps"
                  }
                },
                [_vm._v(_vm._s(_vm.configSelezionato.applicazione.descrizione))]
              ),
              _c(
                "v-input",
                {
                  attrs: {
                    messages: [_vm.myMessageRuolo],
                    "prepend-icon": "perm_identity"
                  }
                },
                [_vm._v(_vm._s(_vm.configSelezionato.ruolo.descrizione))]
              ),
              _c(
                "v-input",
                {
                  attrs: {
                    messages: [_vm.configSelezionato.fileConfig.descrizione],
                    "prepend-icon": "description"
                  }
                },
                [_vm._v(_vm._s(_vm.configSelezionato.fileConfig.descrizione))]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    depressed: "",
                    center: "",
                    "x-large": "",
                    accent: "",
                    color: "primary"
                  },
                  on: { click: _vm.avviaGeoViewer }
                },
                [_vm._v("Avvia GeoViewerCore")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("Dati del GeoViewer da avviare:")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }